<template>
  <div>
    <b-row class="d-flex justify-content-between header mb-1">
      <b-col>
        <h1 class="serverName text-primary ml-2">
          <strong>
            {{ `${serviceInfo.identifier}` }}
          </strong>
        </h1>
      </b-col>
    </b-row>
    <b-row class="ml-2 mb-2 d-flex justify-content-between">
      <div>
        {{ $t("common.terms.created_at") }}:
        <strong class="text-primary"> {{ serviceInfo.created_at }}</strong>
      </div>
      <div>
        {{ $t("common.terms.updated_at") }}:
        <strong class="text-primary mr-3"> {{ serviceInfo.updated_at }}</strong>
      </div>
    </b-row>

    <b-tabs
      horizontal
      nav-wrapper-class="nav-bottom"
      class="ml-2"
      v-model="currentTab"
    >
      <b-tab title="Info">
        <b-row>
          <b-col md="6" class="mt-4">
            <b-row>
              <b-col class="mb-1" cols="12">
                <b-form-group
                  label="Service Identifier"
                  label-for="service-identifier"
                  label-cols-md="4"
                >
                  <b-input-group
                    v-if="serviceInfo.identifier"
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="BoxIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="service-identifier"
                      :value="`  ${serviceInfo.identifier}`"
                      readonly
                    />
                    <b-input-group-append is-text>
                      <div
                        v-if="!userCantEdit"
                        class="ml-1 btn btn-warning text-white py-25 px-75"
                        @click="editService('Identifier')"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="`Edit2Icon`"
                        />
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12">
                <b-form-group
                  label="Base Path"
                  label-for="base-path"
                  label-cols-md="4"
                >
                  <b-input-group
                    v-if="serviceInfo.identifier"
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LinkIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="base-path"
                      placeholder="Plan Category"
                      readonly
                      :value="serviceInfo.base_path"
                    />
                    <b-input-group-append is-text>
                      <div
                        v-if="!userCantEdit"
                        class="ml-1 btn btn-warning text-white py-25 px-75"
                        @click="editService('Base_Path')"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="`Edit2Icon`"
                        />
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12">
                <b-form-group
                  :label="$t('info.token')"
                  label-for="gx-service-id"
                  label-cols-md="4"
                >
                  <b-input-group
                    v-if="serviceInfo.token"
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <div
                        class="btn btn-info text-white py-25 px-75"
                        v-clipboard:copy="serviceInfo.token"
                        v-clipboard:success="onCopy"
                        v-b-tooltip.hover.v-black
                        :title="$t('tooltip.clipboard')"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          icon="FileTextIcon"
                        />
                      </div>
                    </b-input-group-prepend>
                    <b-form-input
                      id="gx-service-id"
                      readonly
                      :type="serviceTokenInputType"
                      :value="`${serviceInfo.token}`"
                    />
                    <b-input-group-append is-text>
                      <div
                        v-if="!userCantEdit"
                        class="btn btn-secondary text-white py-25 px-75"
                        @click="toggleServiceTokenVisibility()"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          :icon="serviceTokenIcon"
                        />
                      </div>
                      <div
                        v-if="!userCantEdit"
                        class="ml-1 btn btn-warning text-white py-25 px-75"
                        @click="refreshToken()"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          icon="RefreshCwIcon"
                        />
                      </div>
                    </b-input-group-append>
                  </b-input-group>
                  <div v-else>
                    <b-skeleton type="input"></b-skeleton>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6" cols="12" v-if="serviceInfo.identifier">
            <scoped-vars
              :identifier="`$NONE.${serviceInfo.identifier}`"
              scope="@MIDDLEWARES"
            ></scoped-vars>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :title="$t('service.permission')" lazy>
        <b-row class="d-flex justify-content-between header">
          <b-col> </b-col>
          <b-button
            v-if="!userCantEdit"
            class="mr-2"
            variant="relief-success"
            @click="$refs['add-permission-modal'].show()"
          >
            + Add Permission
          </b-button>
        </b-row>

        <hr class="my-2" />
        
        <BsTable
          :key="'permissions-service-bstable-'+tableKey"
          tableName="serviceEvents"
          :fields="tableFields.serviceEvents"
          :actions="tableActions.serviceEvents"
          :has_paginator="false"
          :show_filter="true"
          :checkToggleItems="[
            { name: 'can_receive', event: 'changeCanReceive' },
            { name: 'can_send', event: 'changeCanSend' },
          ]"
        />
      </b-tab>
    </b-tabs>
    

    <b-modal
      ref="add-permission-modal"
      title="Creating Permission"
      centered
      @ok="handleAddPermission"
      @hide="(e)=>{ if (modalInfo.loading) e.preventDefault()}"
      no-close-on-backdrop
    >
      <template #modal-ok>
        <span v-if="!modalInfo.loading">
          Confirm
        </span>
        <b-spinner v-else small/>
      </template>

      <label>Service</label>
      
      <v-select
        :options="getTransmissionEvents"
        :clearable="false"
        v-model="modalInfo.selectedEvent"
      >
        <template #selected-option="data">
          {{data.event_identifier}}
        </template>
        <template #option="data">
          {{data.event_identifier}}
        </template>

      </v-select>

      

    </b-modal>

    <base-modal />
  </div>
</template>

<script>
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import BsTable from "@/layouts/components/BsTable.vue";
import EventBus from "@/custom/eventBus";
import { makeToast } from "@/layouts/components/Popups.js";
import ScopedVars from "@/layouts/components/Transmission/ScopedVars.vue";
import VSelect from 'vue-select'
import { mapGetters } from "vuex";
import { errorToast, successToast } from "@/custom/class/FunctionClasses/CommonToasts";

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BTabs,
  BTab,
  BCardText,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BaseModal,
    BSkeleton,
    BsTable,
    VBTooltip,
    ScopedVars,
    BSpinner,
    VSelect
  },
  data() {
    return {
      serviceInfo: [],
      tableFields: {
        serviceEvents: [
          {
            key: "id",
            label: this.$t("common.table.fields.id"),
            sortable: true,
          },
          {
            key: "identifier",
            label: this.$t("common.table.fields.identifier"),
            sortable: true,
          },
          {
            key: "can_send",
            label: this.$t("events.table.fields.send"),
            sortable: true,
          },
          {
            key: "can_receive",
            label: this.$t("events.table.fields.receive"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
        serviceWebhook: [
          {
            key: "identifier",
            label: this.$t("common.table.fields.identifier"),
            sortable: true,
          },
          {
            key: "status",
            label: this.$t("services.table.fields.status_active"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("common.table.fields.created_at"),
            sortable: true,
          },
          {
            key: "actions",
            label: this.$t("common.table.fields.actions"),
          },
        ],
      },
      tableActions: {
        serviceEvents: custom.tableActions.serviceEvents,
        serviceWebhook: custom.tableActions.serviceWebhook,
      },
      serviceTokenIcon: "EyeIcon",
      serviceTokenInputType: "password",
      serviceTokenVisible: false,
      currentTab: 0,
      modalInfo: {
        selectedEvent: undefined,
        loading: false        
      },
      tableKey: 0
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },
  computed: {
    ...mapGetters(['getTransmissionEvents']),

    userCantEdit() {
      return !this.$can('create') 
    }
  },
  mounted() {
    this.init();

    EventBus.$on("changeCanReceive", (payload) => {
      this.$store
        .dispatch("changeCanReceive", {
          item: payload.item,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          EventBus.$emit(payload.done_event, payload.done);

          makeToast(custom.successMessages.canReceive);
        })
        .catch((e) => {
          makeToast(custom.errorMessages.canReceive);
          console.log(e);
        });
    });

    EventBus.$on("changeCanSend", (payload) => {
      this.$store
        .dispatch("changeCanSend", {
          item: payload.item,
          transmissionID: this.$route.params.transmissionID,
        })
        .then((response) => {
          EventBus.$emit(payload.done_event, payload.done);

          makeToast(custom.successMessages.canSend);
        })
        .catch(() => {
          makeToast(custom.errorMessages.canSend);
        });
    });
  },
  beforeDestroy() {
    EventBus.$off("changeCanSend");
    EventBus.$off("changeCanReceive");
  },
  watch: {
    currentTab() {
      location.href = `#${this.currentTab}`;
    },
  },
  methods: {
    init() {
      this.fetchServiceById();
      this.fetchEvents();

      this.buildActions();
    },
    buildActions() {
      // this.tableActions.serviceEvents["second-slot"] = {
      //   type: "exec",
      //   title: this.$t("middleware.tooltip.service_event_middlewares"),
      //   action: this.openMiddlewares,
      //   icon: "GitPullRequestIcon",
      //   color: "text-success",
      //   variantColor: "outline-success",
      // };

      // this.tableActions.serviceWebhook["third-slot"] = {
      //   type: "exec",
      //   title: this.$t("middleware.tooltip.webhook_middlewares"),
      //   action: this.openWebhookMiddlewares,
      //   icon: "GitPullRequestIcon",
      //   color: "text-success",
      //   variantColor: "outline-success",
      // };

      this.tableActions.serviceWebhook["fourth-slot"] = {
        type: "exec",
        title: this.$t("middleware.tooltip.duplicate_webhook"),
        action: this.duplicateWebhook,
        icon: "CopyIcon",
        color: "text-warning",
        variantColor: "outline-warning",
      };

      this.tableActions.serviceEvents["third-slot"] = {
        type: "exec",
        title: this.$t("middleware.tooltip.copy_payload_to_clipboard"),
        action: this.getPayloadJSON,
        icon: "FileTextIcon",
        color: "text-warning",
        variantColor: "outline-warning",
      };
    },
    openMiddlewares: function (item) {
      this.$router.push({
        name: "serviceEventMiddlewares",
        params: { serviceID: item.service.id, eventID: item.event.id },
      });
    },
    openWebhookMiddlewares: function (item) {
      this.$router.push({
        name: "webhookMiddlewares",
        params: { webhookID: item.id },
      });
    },
    fetchEvents(){
      this.$store.dispatch('getEventsByTransmissionGroup', {transmissionID: this.$route.params.transmissionID}).then((resp)=>{

      })
      .catch((err)=>{
        console.error(err)
      })
    },
    duplicateWebhook: function (item) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t("service_event.modal.confirm_duplicate_webhook.message", {
            identifier: this.$t(item.identifier),
          }),
          {
            title: this.$t(
              "service_event.modal.confirm_duplicate_webhook.title"
            ),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("common.terms.yes"),
            cancelTitle: this.$t("common.terms.no"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.$store.dispatch("setLoadingState", true);

            this.$store
              .dispatch("exportWebhookByIDs", {
                id_list: [parseInt(item.id)],
                ...this.$route.params,
              })
              .then((response) => {
                let importScript = JSON.parse(response.script);
                let uniqueIdentifier = Math.floor(Math.random() * 999) + 100;
                importScript["webhooks"][
                  `${item.identifier}Copy${uniqueIdentifier}`
                ] = importScript["webhooks"][item.identifier];
                delete importScript["webhooks"][item.identifier];

                this.$store
                  .dispatch("importScript", {
                    script: JSON.stringify(importScript),
                    ...this.$route.params,
                  })
                  .then((response) => {
                    this.$store.dispatch("setLoadingState", false);
                    EventBus.$emit("resetBsTable", "serviceWebhook");

                    makeToast({
                      title: this.$t(
                        "service_event.toast.duplicate_webhook_import.success.title"
                      ),
                      text: this.$t(
                        "service_event.toast.duplicate_webhook_import.success.message"
                      ),
                      variant: "success",
                      icon: "CheckIcon",
                    });
                  })
                  .catch(() => {
                    this.$store.dispatch("setLoadingState", false);
                    makeToast({
                      title: this.$t(
                        "service_event.toast.duplicate_webhook_import.error.title"
                      ),
                      text: this.$t(
                        "service_event.toast.duplicate_webhook_import.error.message"
                      ),
                      variant: "danger",
                      icon: "XIcon",
                    });
                  });
              })
              .catch(() => {
                this.$store.dispatch("setLoadingState", false);

                makeToast({
                  title: this.$t(
                    "service_event.toast.duplicate_webhook_export.error.title"
                  ),
                  text: this.$t(
                    "service_event.toast.duplicate_webhook_export.error.message"
                  ),
                  variant: "danger",
                  icon: "XIcon",
                });
              });
          }
        });
    },
    fetchServiceById() {
      this.$store
        .dispatch("getServicebyId", { ...this.$route.params })
        .then((response) => {
          console.log(response);
          this.$store.commit("SET_CURRENT_SERVICE", response);
          this.serviceInfo = response;
          this.currentTab = parseInt(this.getHash().substring(1));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleServiceTokenVisibility() {
      this.serviceTokenVisible = !this.serviceTokenVisible;
      if (this.serviceTokenVisible) {
        this.serviceTokenIcon = "EyeOffIcon";
        this.serviceTokenInputType = "text";
      } else {
        this.serviceTokenIcon = "EyeIcon";
        this.serviceTokenInputType = "password";
      }
    },
    editService(action) {
      this.$store.dispatch("setServiceAction", action).then(() => {
        this.$store
          .dispatch("setModalObj", `editService${action}`)
          .then(() => {
            this.$store.dispatch("setActiveModal", true);
          })
          .catch(() => {
            console.log(error);
          });
      });
    },
    refreshToken() {
      let modalData = custom.confirmModalStructure.changeServiceToken;
      this.$bvModal
        .msgBoxConfirm(this.$t(modalData.message), modalData.config)
        .then((value) => {
          if (value) {
            this.$store.dispatch("setLoadingState", true);
            this.$store
              .dispatch("changeServiceToken", { ...this.$route.params })
              .then(() => {
                this.$store.dispatch("setLoadingState", false);
                this.init();

                makeToast(
                  custom.successMessages[action] ||
                    custom.successMessages.default
                );
              })
              .catch((error) => {
                this.$store.dispatch("setLoadingState", false);

                makeToast(
                  custom.errorMessages[error.code ? error.code : action]
                );
              });
          } else {
            makeToast(custom.warningMessages[action]);
          }
        });
    },
    handleAddPermission(e) {
      e.preventDefault()
      if (!this.modalInfo.selectedEvent){
        return
      }
      this.modalInfo.loading = true
      const payload = {
        transmissionID: this.$route.params.transmissionID,
        data:{
          event: this.modalInfo.selectedEvent.id,
          service: this.serviceInfo.id
        }
      }
      this.$store.dispatch('addEventPermission', payload).then((resp)=>{

        successToast({text: 'Permission added'})
        this.modalInfo.loading = false
        
        this.$nextTick(()=>{
          this.$refs['add-permission-modal'].hide()
        })
        this.tableKey++
      })
      .catch((err)=>{
        console.error(err)
        errorToast({text:"Permission could not be created"})
      })
      .finally(()=>{
        this.modalInfo.loading = false
      })

    },
    handleAddWebhook() {
      this.$store
        .dispatch("setModalObj", "addServiceWebhook")
        .then(() => {
          this.$store.dispatch("setActiveModal", true);
        })
        .catch(() => {
          console.log(error, "error - handleAddWebhook --->>>>");
        });
    },
    onCopy() {
      makeToast(custom.successMessages.onCopy);
    },
    getEventObjectField() {
      return this.$store.dispatch("getEventObjectField", this.$route.params);
    },
    async getPayloadJSON(item) {
      console.log(item);

      this.$store.commit("SET_CURRENT_EVENT", item.event);

      const obj = await this.getEventObjectField();

      const j = {
        event: item.event.event_identifier,
        token: this.serviceInfo.token,
        sent_from: this.serviceInfo.identifier,
        data: obj,
      };

      navigator.clipboard
        .writeText(JSON.stringify(j))
        .then(() => {
          makeToast({
            title: this.$t("service_event.toast.copy_payload.success.title"),
            text: this.$t("service_event.toast.copy_payload.success.message"),
            variant: "success",
            icon: "CheckIcon",
          });
        })
        .catch(() => {
          makeToast({
            title: this.$t("service_event.toast.copy_payload.error.title"),
            text: this.$t("service_event.toast.copy_payload.error.message"),
            variant: "danger",
            icon: "XIcon",
          });
        });
    },
    getHash() {
      return location.hash;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>