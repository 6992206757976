 
<template>
  <div v-if="getBaseModalObj != ''">
    <b-form ref="form" class="repeater-form">
      <b-modal
        :visible="getBaseModalObj.isActive"
        id="base-modal"
        :title="$t(`base_modal.title.${getBaseModalObj.modalConfig.title}`)"
        :centered="getBaseModalObj.modalConfig.centered"
        :ok-title="$t('common.terms.submit')"
        cancel-variant="outline-danger"
        :size="getBaseModalObj.modalConfig.size"
        @ok.prevent="handleSubmit"
        @cancel.prevent="handleCancel"
        :no-close-on-backdrop="
          getBaseModalObj.modalConfig.backdropClickDontClose
        "
      >
        <validation-observer ref="modalValidation">
          <b-row
            v-for="(items, rowIndex) in getBaseModalObj.structure"
            :key="rowIndex"
            :id="`row${rowIndex}`"
            class="mb-1"
          >
            <b-col
              v-for="(item, colIndex) in items"
              :id="item"
              :key="colIndex"
              ref="row"
              :xl="item.colSize.xl"
              :lg="item.colSize.lg"
              :md="item.colSize.md"
              :sm="item.colSize.sm"
              :cols="item.colSize.cols"
            >
              <b-form-group :label="$t(`base_modal.label.${item.label}`)" :label-for="item.name">
                <!-- INPUT  -->
                <div v-if="item.fieldType === 'input'">
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name"
                    :rules="item.rules"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        :id="item.name + colIndex"
                        :name="item.name"
                        :type="item.type"
                        :state="errors.length > 0 ? false : null"
                        v-model="metaData[item.vmodel]"
                        :placeholder="$t(`base_modal.placeholder.${item.placeholder}`)"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- SELECT  -->
                <div v-else-if="item.fieldType === 'select'">
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name"
                    :rules="item.rules"
                  >

                    <template v-if="selectOptions[item.preRenderFunction]">
                      <b-form-select
                        :id="item.name + colIndex"
                        v-model="metaData[item.vmodel]"
                        
                        >
                        <b-form-select-option
                          v-for="(option, indexSelect) in selectOptions[item.preRenderFunction]"
                          :key="indexSelect"
                          :value="option.id"
                        >
                          {{ $t(option.label) }}
                        </b-form-select-option>
                        <template #first>
                          <b-form-select-option :value="undefined" disabled
                            >-- {{ $t('common.terms.select_please')}} --</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- SELECT 2 -->
                <div v-else-if="item.fieldType === 'select2'">
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name"
                    :rules="item.rules"
                  >
                    <v-select
                      :id="item.name + colIndex"
                      :filterable="false"
                      v-model="metaData[item.vmodel]"
                      :setSearchFunction="setSearchFunction(item.onSearch)"
                      :options="select2Options[item.onSearch]"
                      @search="onSearch"
                      :getOptionLabel="(option) => option[item.slotOption]"
                    >
                      <template slot="no-options">
                        {{ $t(`base_modal.placeholder.${item.noOptionLabel}`) }}
                      </template>
                      <template v-slot:option="option">
                        {{ option[item.slotOption] }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- SPECIFIC SELECT  -->
                <div v-else-if="item.fieldType === 'specificSelect'">
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name"
                    :rules="item.rules"
                  >
                    <b-form-select
                      :id="item.name + colIndex"
                      v-model="metaData[item.vmodel]"
                      @change="getSpecificSelectItem"
                    >
                      <b-form-select-option
                        v-for="(option, indexSelect) in selectOptions[
                          item.preRenderFunction
                        ]"
                        :key="indexSelect"
                        :value="option[item.value]"
                      >
                        {{ $t(option[item.slotOption]) }}
                      </b-form-select-option>
                      <template #first>
                        <b-form-select-option :value="undefined" disabled
                          >-- {{ $t('common.terms.select_please')}} --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- SPECIFIC INPUT THAT DEPENDS ON SPECIFIC SELECT  -->
                <div v-if="item.fieldType === 'specificInput'">
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name"
                    :rules="item.rules"
                    v-if="specificSelect_SelectedItem != 1"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        :id="item.name + colIndex"
                        :name="item.name"
                        :type="item.type"
                        :state="errors.length > 0 ? false : null"
                        v-model="metaData[item.vmodel]"
                        :placeholder="$t(`base_modal.placeholder.${item.placeholder}`)"
                        min="0"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- TEXTAREA  -->
                <div v-else-if="item.fieldType === 'textarea'">
                  <validation-provider
                    #default="{ errors }"
                    :name="item.name"
                    :rules="item.rules"
                  >
                    <b-form-textarea
                      :id="item.name + colIndex"
                      v-model="metaData[item.vmodel]"
                      :placeholder="$t(`base_modal.placeholder.${item.placeholder}`)"
                      :rows="item.rows"
                      :max-rows="item.max_rows"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- SWITCH -->
                <div v-else-if="item.fieldType === 'switch'">
                  <b-form-checkbox
                    :checked="item.checked"
                    :id="item.name + colIndex"
                    v-model="metaData[item.vmodel]"
                    :class="`custom-control-${item.variant}`"
                    class="ml-1 mt-1"
                    switch
                  >
                    <template v-if="item.iconLeft && item.iconRight">
                      <span class="switch-icon-left">
                        <feather-icon :icon="item.iconLeft" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon :icon="item.iconRight" />
                      </span>
                    </template>

                  </b-form-checkbox>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BRow,
  BFormSelect,
  BCol,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, max_value } from "@validations";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { makeToast } from "@/layouts/components/Popups";
import custom from "@/custom";
import { mapGetters } from "vuex";

export default {
  // props: {
  //   title: {
  //     type: String,
  //     required: true,
  //   },
  // },
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BFormSelect,
    BCol,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pageTitle: this.$route.meta.pageTitle,
      metaData: [],
      required,
      email,
      max_value,
      select2Options: [],
      selectOptions: {},
      // getBaseModalObj: [],
      searchFunction: "",
      specificSelect_SelectedItem : null,
    };
  },
  props: ["initialDataModal"],
  computed: {
    ...mapGetters(["getBaseModalObj"]),
  },
  updated() {
    this.init();
  },
  async mounted() {
    // console.log(getBaseModalObj, 'nothing');
    if (this.getBaseModalObj.structure) {
      this.init();
    }
  },
  methods: {
    async init() {
      let col = [];
      let row = [];
      let colsLength = 0;
      let rowsLength = Object.keys(this.getBaseModalObj.structure).length;

      for (let i = 1; i < rowsLength + 1; i++) {
        row = this.getBaseModalObj.structure[`row${i}`];
        // console.log(row)
        colsLength = Object.keys(row).length;

        for (let j = 1; j < colsLength + 1; j++) {
          col = this.getBaseModalObj.structure[`row${i}`][`col${j}`];

          if ((col.fieldType === "select") || (col.fieldType === "specificSelect")) {
            await this.$store
              .dispatch(col.preRenderFunction, { ...this.$route.params })
              .then((response) => {
                let items = []
                response.items.forEach(el => {
                  if (el){
                    items.push({
                      id: el.id,
                      label: el.label,
                    })
                  }
                });
                this.$set(
                  this.selectOptions,
                  col.preRenderFunction,
                  items
                );
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
      }

      if (this.initialDataModal) {
        let aux = this.getBaseModalObj.structure.row1.col1.vmodel
  
        this.metaData[aux] = this.initialDataModal[aux]
      }
    },
    async onSearch(search, loading) {
      this.$emit("setSearchFunction");

      if (search.length > 3) {
        this.$store
          .dispatch(this.searchFunction, search)
          .then((response) => {
            if (this.searchFunction) {
              this.$set(this.select2Options, this.searchFunction, response);
            }
            return;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.optionUser = [];
      }
    },
    handleSubmit() {
      this.$refs.modalValidation.validate().then((success) => {
        if (success) {
          this.$store.dispatch("setLoadingState", true);
          this.$store
            .dispatch(this.getBaseModalObj.submitAction, { data: this.metaData, ...this.$route.params })
            .then((response) => {
              if (response.error) {
                throw response;
              }

              this.refreshInfo();
              if (this.getBaseModalObj.maintainActive !== true) {
                this.$store.dispatch("setActiveModal", false).then(() => {
                  this.metaData = [];
                });
              }

              this.$root.$emit("bv::hide::modal", "modal-info");
              this.$store.dispatch("setLoadingState", false);
              makeToast(
                custom.successMessages[this.getBaseModalObj.submitAction]
              );
            })
            .catch((error) => {
              console.error(error)
              this.$store.dispatch("setLoadingState", false);

              makeToast(
                custom.errorMessages[
                  error.code ? error.code : this.getBaseModalObj.submitAction
                ]
              );
            });
        }
      });
    },
    async handleCancel() {
      await this.$store.dispatch("setActiveModal", false).then(() => {
        this.clearVModels();
      });
    },
    clearVModels() {
      this.metaData = [];
    },
    async refreshInfo() {
      if (this.$route.meta.refreshTable) {
        // await this.$store.dispatch(tableRef, this.$route.params || null)
        this.$root.$refs.bsTable.renderTable();
      } 
      if (this.$route.meta.refreshInput){
        this.$root.$refs[this.$route.meta.pageTitle].init();
      }
    },
    cloneForm() {
      // this.$store
      // 	.dispatch(this.getBaseModalObj.cloneFunction)
      //   .then((response) => {
      // 		console.log(response)
      // 		this.getBaseModalObj.structure = (response)
      // 	})
      //   .catch((error) => {
      // 		console.log(error)
      // 	});
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    setSearchFunction(searchFunction) {
      this.searchFunction = searchFunction;
    },
    async getSpecificSelectItem(selectedItem){
      this.metaData.max_retry = await 0
      this.specificSelect_SelectedItem = await selectedItem
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>