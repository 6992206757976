<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-1">
        <label class="d-inline-block text-sm-left mr-50">{{ $t('common.table.filter.per_page')}}</label>
        <b-form-select
          id="perPageSelect"
          v-model="sortData.per_page"
          size="sm"
          :options="pageOptions"
          class="w-50"
          @change="has_paginator ? tableFilter() : ''"
        >
          <template v-slot:first>
            <option disabled :value="null">---</option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1">
      <b-form-group
        :label="$t('common.table.filter.sort')"
        label-cols-sm="2"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortField"
            :options="sortColumnOptions"
            class="w-50"
            @change="
              sortType !== null ? (has_paginator ? tableFilter() : '') : ''
            "
          >
            <template v-slot:first>
              <option disabled :value="null">{{ $t('common.table.filter.choose')}}</option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortType"
            size="sm"
            :disabled="!sortField"
            class="w-25"
            :options="sortOptions"
            @change="has_paginator ? tableFilter() : ''"
          >
            <template v-slot:first>
              <option disabled :value="null">{{ $t('common.table.filter.choose')}}</option>
            </template>
          </b-form-select>
          <b-input-group-append>
            <b-button
              :disabled="sortField === null"
              @click="
                sortType = null;
                sortField = null;
                sortData.order = null;
                has_paginator ? tableFilter() : '';
              "
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="6" class="my-1">
      <div v-if="has_paginator && show_filter">
        <b-form-group
          :label="$t('common.table.filter.filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              v-model="searchField"
              size="sm"
              class="w-10"
              :options="tableConfig.searchOptions"
              @change="searchFilter ? tableFilter() : ''"
            >
              <template v-slot:first>
                <option disabled :value="null">{{ $t('common.table.filter.choose')}}</option>
              </template>
            </b-form-select>
            <b-form-input
              id="filterInput"
              class="w-50"
              :disabled="!searchField"
              v-model="searchFilter"
              type="search"
              placeholder="Type to Search"
            />
            <!-- @keyup.prevent="has_paginator ? '' : " -->
            <b-input-group-append>
              <b-button
                :disabled="searchFilter === null"
                @click="clearSearchData(has_paginator)"
              >
                {{ $t('common.terms.reset')}}
              </b-button>
              <b-button
                variant="primary"
                :disabled="searchFilter === null || searchFilter === ''"
                @click="tableFilter()"
              >
                <feather-icon icon="SearchIcon" size="20" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div v-else-if="show_filter">
        <b-form-group
          :label="$t('common.table.filter.filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              class="w-50"
              v-model="searchFilter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="searchFilter === null"
                @click="clearSearchData(has_paginator)"
              >
                {{ $t('common.terms.reset') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="sortData.per_page || 10"
        :items="tableItems"
        :fields="fields"
        :busy="tableIsLoading"
        :current-page="has_paginator ? 1 : sortData.page"
        show-empty
        @row-clicked="printClickData"
        :filter="has_paginator ? [] : searchFilter"
        :filter-included-fields="has_paginator ? [] : filterOn"
        @filtered="has_paginator ? '' : onFiltered"
        :empty-text="$t('common.table.no_items')"
        ref="dataTable"
      >
        <template #cell(id)="data">
          <b-badge
            pill
            :variant="`light-${
              data.item.role ? getRolePillColor(data.item.role) : 'info'
            }`"
            class="text-capitalize"
          >
            {{ data.item.id }}
          </b-badge>
        </template>

        <template #cell(role)="data">
          <b-badge
            pill
            :variant="`light-${getRolePillColor(data.item.role)}`"
            class="text-capitalize"
          >
            {{ data.item.role }}
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${
              data.item.status == 'Active' ? 'success' : 'danger'
            }`"
            class="text-capitalize"
          >
            {{ $t(`common.table.fields.${data.item.status.toLowerCase()}`) }}
          </b-badge>
        </template>

        <template #cell(is_public)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="data.item.is_public ? 'CheckIcon' : 'XIcon'"
              size="23"
              class="mr-50"
              :class="`text-${data.item.is_public ? 'success' : 'danger'}`"
            />
          </div>
        </template>

        <template #cell(is_private)="data">
          <div class="text-nowrap">
            <b-button
              pill
              size="sm"
              class="mr-0"
              v-b-tooltip.hover.v-black
              :title="data.item.is_private ? $t('common.table.fields.change_privacy.closed') : $t('common.table.fields.change_privacy.open')"
              :variant="`outline-${
                !data.item.is_private ? 'danger' : 'success'
              }`"
              @click="
              makeAction(
                  custom.customTableActions.planIsPrivate.type,
                  custom.customTableActions.planIsPrivate.action,
                  data.item,
                  custom.customTableActions.planIsPrivate.item,
                )
              "
            >
              <feather-icon
                :icon="(data.item.is_private) ? 'LockIcon' : 'UnlockIcon'"
                size="18"
                class="mr-0"
                :class="`text-${!data.item.is_private ? 'danger' : 'success'}`"
              />
            </b-button>
          </div>
        </template>

         <template #cell(private)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              :variant="`light-${
                data.item.private ? 'danger' : 'primary'
              }`"
              class="text-capitalize"
            >
              {{ data.item.private ? 'Private' : 'Public' }}
            </b-badge>
          </div>
        </template>

         <template v-for="(itemPayload, index) in checkToggleItems" #[`cell(${itemPayload.name})`]="data">
          <div class="text-nowrap" :key="`bs-table-item-${index}`">
            <b-button
              v-if="loadingToggleItems.indexOf(`${data.index}-${itemPayload.name}`) == -1"
              pill
              size="sm"
              class="mr-0"
              v-b-tooltip.hover.v-black
              :variant="`outline-${
                data.item[itemPayload.name] ? 'success' : 'danger'
              }`"
              @click="emitEventCheck(itemPayload.name, itemPayload.event, data.item, data.index)"
            >
              <feather-icon
                :icon="(data.item[itemPayload.name]) ? 'CheckIcon' : 'XIcon'"
                size="18"
                class="mr-0"
                :class="`text-${data.item[itemPayload.name] ? 'success' : 'danger'}`"
              />
            </b-button>
            <div v-else>
              <b-button
                pill
                size="sm"
                class="mr-0"
                v-b-tooltip.hover.v-black
                :variant="`outline-secondary`"
              >
                <b-spinner style="width: 1.35rem; height: 1.35rem;" label="Loading..." />
              </b-button>
            </div>
          </div>
        </template>

        <template #cell(category)="data">
          <b-badge pill variant="light-info" class="text-capitalize">
            {{ data.item.category }}
          </b-badge>
        </template>

        <template #cell(created_at)="data">
          <div>
            {{ data.item.created_at }}
          </div>
          <div v-if="data.item.updated_at">
            <small class="text-muted"
              > {{ $t('common.table.cells.updated_at') }} {{ data.item.updated_at }}</small
            >
          </div>
        </template>

        <template #cell(created_by)="data">
          <div>
            {{ data.item.created_by }}
          </div>
          <small class="text-muted">{{ data.item.created_by_email }}</small>
        </template>

        <template #cell(main_config)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="data.item.main_config ? 'CheckIcon' : 'XIcon'"
              size="22"
              class="mr-50"
              :class="`text-${data.item.main_config ? 'success' : 'danger'}`"
            />
          </div>
        </template>

        <template #cell(price)="data">
          <div>
            <strong>{{ data.item.price }}</strong>
          </div>
          <small class="text-muted"
            >{{ $t('common.table.cells.extra_usage') }}
            <strong>{{ data.item.price_req_extra }}</strong></small
          >
        </template>

        <template
          #table-busy
          v-if="tableIsLoading"
        >
          <b-skeleton-table
            :rows="lastRowsNumber || 1"
            :columns="Object.keys(fields).lenght"
            :table-props="{ bordered: false, striped: true }"
          ></b-skeleton-table>
        </template>
        <template #cell(actions)="item">
          <b-row>
            <div v-for="(action, key) in actions" :key="`${tableName}-${key}`">
              <b-col
                v-if="
                  (action.action &&
                  canSeeAction(
                    action.authorized,
                    item.item.role_id
                  )) 
                "
              >
                <b-button
                  v-b-tooltip.hover.v-black
                  :title="action.title"
                  :variant="action.variantColor"
                  size="sm"
                  @click="
                    makeAction(
                      action.type,
                      action.action,
                      item.item,
                      actions.item
                    )
                  "
                >
                  <feather-icon
                    :icon="action.icon"
                    size="15"
                    class="stroke-current"
                    :class="action.color"
                  />
                </b-button>
              </b-col>
            </div>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination
        @change="has_paginator ? tableFilter() : ''"
        v-model="sortData.page"
        :total-rows="totalItems"
        :per-page="sortData.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
    <l-overlay/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  VBTooltip,
  BSkeletonTable,
  BSpinner,
} from "bootstrap-vue";

import custom from "@/custom";
import { makeToast } from "@/layouts/components/Popups";
import BaseModal from "@/layouts/components/BaseModal";
import LOverlay from "@/layouts/components/LoadingOverlay";
import { mapGetters } from "vuex";
import EventBus from "@/custom/eventBus";

export default {
  props: {
    tableName: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    has_paginator: {
      type: Boolean,
      required: true,
    },
    show_filter: {
      type: Boolean,
      default: true,
    },
    checkToggleItems: {
      type: Array,
      default() {
        return []
      }
    },
    actions: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BSkeletonTable,
    BaseModal,
    LOverlay,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      custom,
      pageOptions: [3, 10, 20, 40, 50, 100],
      sortBy: "",
      sortDesc: "",
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      sortOptions: [
        {
          value: false,
          text: "ASC",
        },
        {
          value: true,
          text: "DESC",
        },
      ],
      // totalRowsLength: "",
      title: "",
      sortData: {
        per_page: 10,
        page: 1,
        order: null,
      },
      searchFilter: null,
      searchField: null,
      sortField: null,
      sortType: null,
      lastRowsNumber: 5,
      tableConfig: [],
      tableItems: [],
      infoTableName: "",
      totalItems: '',
      loadingToggleItems: [],
      tableIsLoading: true
    };
  },
  created() {
    this.$root.$refs.bsTable = this;
  },
  computed: {
    ...mapGetters([
      "getIsBTableBusy",
      "getLoadingState",
      "getAbility"
    ]),
    setTitle() {
      return (this.title = this.$route.meta.pageTitle);
    },
    setTableConfig() {
      return (this.tableConfig = custom.tableConfig[this.tableName]);
    },
    sortColumnOptions() {
      if (this.has_paginator) {
        return this.tableConfig.sortOptions;
      }
      // Create an options list from our fields
      // this.totalRowsLength = this.totalRows;
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.title = this.$route.meta.pageTitle;
    this.tableConfig = custom.tableConfig[this.tableName];

    EventBus.$on("eventCheckDoneProcessing", (item) => {
      const splitted = item.split('-');

      this.tableItems[splitted[0]][splitted[1]] = !this.tableItems[splitted[0]][splitted[1]];

      const index = this.loadingToggleItems.indexOf(item);
      if(index > -1) {
        this.loadingToggleItems.splice(index, 1);
      }
    });

    EventBus.$on("resetBsTable", (tableName) => {
      if(tableName == this.tableName) {
        this.init();
      }
    });

    this.init();
  },
  beforeDestroy () {
    EventBus.$off('eventCheckDoneProcessing')
    EventBus.$off('resetBsTable')
  },
  methods: {
    init() {
      this.renderTable();
    },
    renderTable() {
      this.tableIsLoading = true

      this.tableItems = [];
      
      this.$route.params ? this.resolveParams(this.$route.params) : "";
      this.$store
        .dispatch(this.tableConfig.fetchDataFunction, this.sortData)
        .then((response) => {
          if(response.totalItems){
            this.totalItems = response.totalItems
            this.lastRowsNumber = this.totalItems - 1;
          } else {
            this.lastRowsNumber = response.length - 1;
          }

          this.tableItems = response;

          this.tableIsLoading = false

          this.$store.commit("SET_IS_BTABLE_BUSY", false);

          return
        })
        .catch((error) => {
          this.tableIsLoading = false
          
          console.error(error);
          // makeToast(custom.errorMessages[error.code ? error.code : action]);
        });
    },
    tableFilter() {
      if (this.sortField !== null && this.sortType !== null) {
        this.sortData.order =
          this.sortField + " " + (this.sortType ? "DESC" : "ASC");
      }
      if (this.searchFilter !== null && this.searchField !== null) {
        this.sortData[this.searchField] = `%${this.searchFilter}%`;
        this.sortData[`cmp_${this.searchField}`] = "lk";
      }


      this.$nextTick(()=>{
        this.$store
        .dispatch(this.tableConfig.fetchDataFunction, { ...this.sortData, ...this.$route.params })
        .then((response) => {

          this.totalItems = response.totalItems
          if (response.length == 0) {
            makeToast(custom.infoMessages.no_results);
            this.$store.commit("SET_IS_BTABLE_BUSY", false);

            return;
          }
          this.$store.commit("SET_IS_BTABLE_BUSY", false);

          this.tableItems = response;
          this.$store.commit("SET_IS_BTABLE_BUSY", false);

        })
        .catch((error) => {
          this.$store.commit("SET_IS_BTABLE_BUSY", false);

          // makeToast(custom.errorMessages[error.code ? error.code : action]);
        });
      })


    },
    searchFunction() {
      this.$store
        .dispatch(this.tableConfig.filterFunction, this.searchFilter)
        .then((response) => {
          if (response.length == 0) {
            makeToast(custom.errorMessages.addUserToOrganization);
          }
          this.tableItems = response;
          this.$store.commit("SET_IS_BTABLE_BUSY", false);

          // makeToast(custom.successMessages[action]);
        })
        .catch((error) => {
          console.error(error);
          // makeToast(custom.errorMessages[error.code ? error.code : action]);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalItems = filteredItems.length
      this.sortData.page = 1;
    },
    async makeAction(type, action, item, storeCommit) {
      switch (true) {
        case type === "add": {
          this.$store.commit(storeCommit, item);
          this.$store.dispatch("setModalObj", action).then(() => {
            this.$store.dispatch("setActiveModal", true);
          });

          break;
        }
        case type === "edit": {
          this.$store.commit(storeCommit, item);
          this.$store.dispatch("setModalObj", action).then(() => {
            this.$store.dispatch("setActiveModal", true);
          });

          break;
        }
        case type === "delete": {
          this.$store.commit(storeCommit, item);
          let modalData = custom.confirmModalStructure[action];
          // let name = item.name
          //   ? item.name.toUpperCase()
          //   : item.fullname.toUpperCase();
          this.$bvModal
            .msgBoxConfirm(this.$t(modalData.message),
              {
                title: this.$t(modalData.config.title),
                okTitle: this.$t(modalData.config.okTitle),
                okVariant: modalData.config.okVariant,
                cancelTitle: this.$t(modalData.config.cancelTitle),
                cancelVariant: modalData.config.cancelVariant,
                size: modalData.config.size,
                hideHeaderClose: modalData.config.hideHeaderClose,
                centered: modalData.config.centered,
              }
            )
            .then((value) => {
              if (value) {
                this.$store.dispatch("setLoadingState", true);
                this.$store
                  .dispatch(action, { item: item, ...this.$route.params })
                  .then(() => {
                    this.$store.dispatch("setLoadingState", false);
                    this.init();
                    // this.$store.dispatch(this.$route.meta.renderFuntion, this.$route.params || null).then(response => {

                    makeToast(custom.successMessages[action]);
                    // })
                  })
                  .catch((error) => {
                    this.$store.dispatch("setLoadingState", false);

                    makeToast(
                      custom.errorMessages[error.code ? error.code : action]
                    );
                  });
              } else {
                makeToast(custom.warningMessages[action]);
              }
            });
          break;
        }
        case type === "info": {
          this.$store.commit(storeCommit, await item);
          this.infoTableName = this.tableConfig.infoTableName;
          this.$root.$emit("bv::show::modal", "modal-info");

          break;
        }
        case type === "exec": {
          action(item);
          break;
        }
        case type === "newPage": {
          this.$store.commit(storeCommit, item);
          this.$store.dispatch(action, { ...this.$route.params });
          break;
        }
        case type === "confirm": {
          // this.$store.commit(storeCommit, item);
          let modalData = custom.confirmModalStructure[action];
          // let name = item.name
          //   ? item.name.toUpperCase()
          //   : item.fullname.toUpperCase();
          this.$bvModal
            .msgBoxConfirm(modalData.message + " ?", modalData.config)
            .then((value) => {
              if (value) {
                this.$store.dispatch("setLoadingState", true);
                this.$store
                  .dispatch(action, item)
                  .then((response) => {
                    this.init();
                    // this.$store.dispatch(this.$route.meta.renderFuntion, this.$route.params || null).then(response => {
                    this.$store.dispatch("setLoadingState", false);

                    makeToast(custom.successMessages[action]);
                    // })
                  })
                  .catch((error) => {
                    this.$store.dispatch("setLoadingState", false);

                    makeToast(
                      custom.errorMessages[error.code ? error.code : action]
                    );
                  });
              } else {
                makeToast(custom.warningMessages[action]);
              }
            });
          break;
        }
        default:{
          
        }
      }
    },
    canSeeAction(accessLevel, role) {
      if (!role) {
        return true;
      } else if (this.getAbility.id === 666) {
        return true;
      }
      return accessLevel.includes(role) ? true : false;
    },
    getRolePillColor(role) {
      switch (true) {
        case role === "Owner": {
          return "success";
        }
        case role === "Admin": {
          return "info";
        }
        case role === "Developer": {
          return "warning";
        }
      }
    },
    clearSearchData(state){
      if(state){
        delete this.sortData[this.searchField]; 
        delete this.sortData[`cmp_${this.searchField}`]; 
        this.searchFilter = null; 
        this.searchField = null;
        this.tableFilter()
      }else{
        this.searchFilter = null; 
        this.searchField = null;
      }
    },
    resolveParams(params) {
      Object.keys(params).forEach((key) => {
        this.sortData[key] = params[key];
      });
    },
    printClickData(data, index) {
    },
    emitEventCheck(key, event, item, index) {
      const obj_key = `${index}-${key}`;
      EventBus.$emit(event, { item: item, done: obj_key, done_event: "eventCheckDoneProcessing" });
      this.loadingToggleItems.push(obj_key);
    }
  },
};
</script>